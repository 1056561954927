import React, { useEffect, useState } from "react";

import "./_downloadMedia.scss";

// Lottie Animation being used - https://lottiefiles.com/61377-download-animation

// * Connection informatino for AWS S3 bucket
const AWS = require('aws-sdk');
AWS.config.update(
  {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  }
);
const s3 = new AWS.S3();
// * End S3 Setup

const DownloadMedia = () => {
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState('');
  const [error, setError] = useState('');
  const [fileToDownload, setFileToDownload] = useState();

  // * On page load
  useEffect(() => {
    setLoading(true);
    // check for the media being downloaded in the url
    if (window && window.location && window.location.search && window.location.search.includes('media')) {
      const searchParams = window.location.search.replace('?', '');
      // just in case the url has extra params that we are not yet accounting for
      const params = searchParams.split('&');
      let isbn = '';
      let mediaItem = '';
      params.forEach(p => {
        if (p.includes('isbn')) {
          isbn = p.replace('isbn=', '');
        } else if (p.includes('media')) {
          // media item being downloaded must match key of existing S3 content
          mediaItem = p.replace('media=', '');
        }
      });
      // if isbn exists, it will be in the ISBN's folder - if not, it's in the root
      setKey(`${isbn !== '' ? `${isbn}/` : ''}${mediaItem}.zip`);
    } else {
      setLoading(false);
    }
    return () => {
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    if (key !== '') {
      onRetrieveMedia();
    }
  }, [key])

  // * On Click of Download Media button - SIGNED URLS
  const onRetrieveMedia = () => {
    setError('');
    s3.getObject(
      { Bucket: process.env.REACT_APP_BUCKET, Key: key },
      function (e, data) {
        if (e != null && e) {
          if (e.toString().includes('does not exist')) {
            setError('The media item you are attempting to download does not exist.');
            setTimeout(() => setLoading(false), 2000);
          }
        } else {
          setFileToDownload(data);
          setTimeout(() => setLoading(false), 2000);
        }
      }
    );
  }

  return (
    <div className="downloadMedia">
      <div className="downloadMediaContainer">
        <div className="downloadMediaHeader">
          <div className="logoAndName">
            <img src="/images/logo_wtext2.png" />
          </div>
          <a href="https://rowman.com/" target="_blank">VISIT ROWMAN.COM</a>
        </div>
        <div className="downloadMediaContent">
          <div className="downloadReady">
            {loading ? (
              <div className="download">
                <lottie-player src="https://assets6.lottiefiles.com/packages/lf20_7iqfdthd.json" speed="1" style={{ width: '100px', height: '100px', background: 'transparent' }} loop autoplay></lottie-player>
                <p>Loading resources...</p>
              </div>
            ) : (
              <div className="download">
                <img src="/images/img_zip.png" />
                {error === '' && key !== '' && fileToDownload ? (
                  <>
                    <p>Your zip file of resources is ready to download.</p>
                    <a href={`https://${process.env.REACT_APP_BUCKET}.s3.amazonaws.com/${key}`} target="_blank"><img src="/images/btn_download.png" /></a>
                  </>
                ) : error !== '' ? (
                  <p>{error}</p>
                ) : <p>There is no media to be downloaded.</p>}
              </div>
            )}
          </div>
        </div>
      </div>
      <div class="disclaimer">
        <p>Please note that loading times can vary depending on internet speeds. For larger files, you may need to wait several minutes before the download becomes available. Consider connecting to a faster network if loading takes too long.</p>
      </div>
    </div>
  )
}

export default DownloadMedia