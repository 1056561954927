import React, { useEffect } from "react";
import { Route } from "react-router-dom";

const Page = (props) => {
  useEffect(() => {
    document.title = "R&L Media Download | " + props.title;
  }, [props.location.pathname, props.title]);

  return <Route {...props} />;
};

export default Page;
