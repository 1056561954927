import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Page from "./routing/page";

import DownloadMedia from "./pages/downloadMedia";
import Error404 from "./pages/error404";

require('dotenv').config()

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Redirect to='/download' />
        </Route>
        <Page exact path='/download' component={DownloadMedia} title='Download Media' />
        {/* Catch all link */}
        <Page path='*' component={Error404} title='Not Found' />
      </Switch>
    </Router>
  );
}

export default App;
